import React, {Component, useContext} from 'react';
import {Routes, Route, Navigate} from 'react-router-dom'
import { publicRoutes } from '../routes';
import { HOME_ROUTE } from '../utils/consts';

const AppRouter = () =>{
    return(
        <Routes>
           {publicRoutes.map(({path, Component}) =>
           <Route key={path} path={path} Component={Component} exact/>
        )}
        <Route  path ="*" element={<Navigate to={HOME_ROUTE} replace />} />

        </Routes>
    );
};

export default AppRouter;