import React from "react";
import {Col, Container, Row, Image} from "react-bootstrap"
import Backgroung from "../static/img/Eye-Examination.jpeg"
import Logo from "../static/img/logo1.png"
const Home = () =>{
    return(
        <Container fluid style={{ minHeight: "100vh", backgroundImage: `url(${Backgroung})`, backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100vw',
        height: '100vh'
         }} >
            <Row>
                <Col md={2}>
            <Image   width={100} height={100} alt="Logo" src= {Logo}/>
                </Col>
                <Col md={10} >
                <h1 className="text-start fw-bold display-4 " id = "logo" style={{ fontFamily: 'ExtraBold'}}>
                    МЕДИЦИНСКИЙ ЦЕНТР  "СФЕРА"
                </h1>
                </Col>
            </Row>
            <Row className="text-center">
                <Col>
                <div className="display-3" id="text">
                     Сайт находится в разработке
                </div>
                </Col>
            </Row>
            <Row>
                <Col>
                <ul className=" h4 list-unstyled" id="uslugi">
                    <li> Здесь будут представлены медицинские услуги по: 
                    <ul>
                        <li>Офтальмологии</li>
                        <li>Терапии</li>
                        <li>Оптометрии</li>
                    </ul>
                     <li>А также услуги по подбору продаже медицинских оправ
                         и аксессуаров к ним</li>
                    </li>
                </ul>
                </Col>
            </Row>
            <Container className="mt-5" id="contact">
            <Row className="text-center">
               <Col className="h4">
               КОНТАКТЫ
               </Col>
            </Row>
            <Row className="mt-5" >
                <Col className="h4 text-center col-2">
                Адрес:
                </Col>
                <Col className="h4 text-start col-10">
                г. Нижний Новгород, пр-т Октября д. 8
                </Col>
            </Row>
            <Row className="h4 mt-3">
                <Col className="text-center col-2" >
                Телефон:
                </Col>
                <Col className="text-start col-10">
                <p>8 (831)219-94-03 </p>
                <p>+7 (910)795-12-13</p>
                </Col>
                <Col className="text-end col-10">
                
                </Col>
            </Row>
            </Container>
                    
        </Container>
    );
};

export default Home;